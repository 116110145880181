
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Gilroy-Bold â˜ž';
    font-style: normal;
    font-weight: normal;
    src: local('x'), url('assets/fonts/Gilroy-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Gilroy-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('x'), url('assets/fonts/Gilroy-Medium.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Gilroy-Bold â˜ž';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
  --rsbs-bg: #fff;
  --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
  --rsbs-max-w: 50vh;
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  /* --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right); */
  --rsbs-overlay-rounded: 16px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#payment-form {
  /* width: 200px;
  height: 800px;
  min-width: 200px; */
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-element {
  margin-bottom: 24px;
}

*:focus {
  outline: none;
  box-shadow: none
}